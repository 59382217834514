<template>
  <div class="public-panel box3 u-m-t-30">
    <h2 class="u-flex u-row-between">
      视频
      <div class="btn btn-blue" @click="videoDialogVisible = true">
        更多视频
      </div>
    </h2>
    <div class="u-flex u-row-around">
      <cust-video
        style="width: 100%; height: 230px; margin-top: 20px"
        :videourl="video[0].mu_url"
      ></cust-video>
    </div>
    <!-- 视频弹窗 -->
    <el-dialog
      class="custom-dialog index-dialog"
      :visible.sync="videoDialogVisible"
    >
      <template v-slot:title>
        <h3>视频</h3>
      </template>
      <div class="u-flex u-col-top">
        <div class="left u-flex-1 u-p-r-62">
          <div class="search">
            <el-input v-model="search" placeholder="请输入内容">
              <i slot="suffix" class="icon-search"></i>
            </el-input>
          </div>
          <el-tree
            class="u-m-t-26"
            :data="treeData"
            :filter-node-method="filterNode"
            default-expand-all
            @node-click="treeClick"
            :props="defaultProps"
          ></el-tree>
        </div>
        <div class="videobox">
          <video id="video" src="" style="height: 100%" controls></video>
        </div>
      </div>
      <div class="off" @click="videoDialogVisible = false"></div>
    </el-dialog>
  </div>
</template>

<script>
import { getPcVideos } from "@/api/screen2";
import CustVideo from "@/components/video";

export default {
  name: "Videos",
  components: { CustVideo },
  data() {
    return {
      search: "",
      videoDialogVisible: false, //视频弹窗
      server_id: this.$store.state.screen.server_id,
      video: [
        {
          mu_url: "",
        },
        {
          mu_url: "",
        },
      ],
      his: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  created() {
    this.getPcVideos();
    const _this = this;
    _this.$once("hook:beforeDestroy", () => {
      _this.destroyHls();
    });
  },
  beforeDestroy: function () {
    this.destroyHls();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    getPcVideos() {
      getPcVideos({}).then((res) => {
        console.log("视频s", res);
        // eslint-disable-next-line no-undef
        if (PLATFROM_CONFIG.is_video === 1) {
          this.video = res.res.video;
        }
        this.treeData = res.res.services;
      });
    },
    treeClick(val) {
      this.destroyHls();
      console.log(val);
      const video = document.getElementById("video");
      // console.log(video)

      // eslint-disable-next-line no-undef
      if (Hls.isSupported()) {
        this.destroyHls();
        // eslint-disable-next-line no-undef
        this.hls = new Hls();

        this.hls.loadSource(val.mu_url);
        this.hls.attachMedia(video);
        // eslint-disable-next-line no-undef
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = val.mu_url;
        video.addEventListener("loadedmetadata", function () {
          video.play();
        });
      }
    },
    destroyHls: function () {
      const video = document.getElementById("video");
      if (this.hls) {
        console.log("已销毁");
        video.pause();
        this.hls.destroy();
        this.hls = null;
      }
    },
  },
};
</script>

<style scoped>
.video-player {
  width: 45%;
  height: 4.39rem;
}
</style>
