<template>
  <div class="echarts">
    <!--    <dv-loading v-if="loading">Loading...</dv-loading>-->
    <div ref="allMap" style="width: 100; height: 100%" />
    <div class="mapChoose">
      <span v-for="(item, index) in parentInfo" :key="item.code">
        <span class="title" @click="chooseArea(item, index)">{{
          item.cityName == "全国" ? "中国" : item.cityName
        }}</span>
        <span v-show="index + 1 != parentInfo.length" class="icon">></span>
      </span>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getBigDataMap } from "@/api/screen";
import tools from "@/unit/autotips";
export default {
  name: "AllMap",
  data() {
    return {
      loading: true,
      myCharts: null,
      geoJson: {
        features: [],
      },
      parentInfo: [
        {
          cityName: "全国",
          code: 100000,
        },
      ],
      projectCity: [
        {
          city: [],
          name: "河南省",
          site: ["许昌智能", "许昌新厂房"],
          children: [
            {
              name: "许昌市",
              children: [
                { name: "建安区", site: ["许昌智能"] },
                { name: "魏都区", site: ["许昌新厂房"] },
                { name: "鄢陵县", site: ["许昌新厂房"] },
              ],
              site: ["许昌智能", "许昌新厂房"],
            },
          ],
        },
        {
          city: [],
          name: "河南省",
          site: ["临颍", "临颍"],
          children: [
            {
              name: "漯河市",
              children: [{ name: "临颍县", site: ["许昌智能2"] }],
              site: ["许昌智能4", "许昌新厂房3"],
            },
          ],
        },
      ],
      pointData: [],
    };
  },
  mounted() {
    getBigDataMap({}).then((res) => {
      this.projectCity = res.res;
      this.$nextTick(() => {
        this.getGeoJson(100000);
      });
    });
  },
  methods: {
    getGeoJson(adcode) {
      const that = this;
      // eslint-disable-next-line no-undef
      AMapUI.loadUI(["geo/DistrictExplorer"], (DistrictExplorer) => {
        var districtExplorer = new DistrictExplorer();
        districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
          if (error) {
            console.error(error);
            return;
          }
          const Json = areaNode.getSubFeatures();
          if (Json.length > 0) {
            that.geoJson.features = Json;
          } else if (Json.length === 0) {
            that.geoJson.features = that.geoJson.features.filter(
              // eslint-disable-next-line eqeqeq
              (item) => item.properties.adcode == adcode
            );
            if (that.geoJson.features.length === 0) return;
          }
          that.getMapData();
        });
      });
    },
    // 获取数据
    getMapData() {
      const mapData = {};
      const pointData = {};
      mapData[0] = [];
      pointData[0] = [];
      // console.log(this.parentInfo);
      this.geoJson.features.forEach((j) => {
        // console.log(j.properties.name);
        const value = Math.round(Math.random());
        // eslint-disable-next-line eqeqeq
        if (j.properties.name != "海南省") {
          mapData[0].push({
            name: j.properties.name,
            value: value,
            cityCode: j.properties.adcode,
          });
          if (this.parentInfo.length === 1) {
            for (let i = 0; i < this.projectCity.length; i++) {
              if (j.properties.name == this.projectCity[i].name) {
                pointData[0].push({
                  name: this.projectCity[i].name,
                  children: this.projectCity[i].children,
                  site: this.projectCity[i].site,
                  value: [j.properties.center[0], j.properties.center[1], 1],
                  city: j.properties.name,
                  cityCode: j.properties.adcode,
                });
              }
            }
          } else {
            let point = this.pointData[this.parentInfo.length - 2];
            for (let i = 0; i < point.length; i++) {
              for (let c = 0; c < point[i].children.length; c++) {
                if (j.properties.name == point[i].children[c].name) {
                  pointData[0].push({
                    name: point[i].children[c].name,
                    children: point[i].children[c].children,
                    site: point[i].children[c].site,
                    value: [j.properties.center[0], j.properties.center[1], 1],
                    city: j.properties.name,
                    cityCode: j.properties.adcode,
                  });
                }
              }
            }
          }
          /*   for (let i=0;i<this.parentInfo.length;i++){
          let index=    this.projectCity.findIndex(item=>{
                return item.name===j.properties.name
            })
            if (index)
          }*/
          /*    for (var i = 0; i < this.projectCity.length; i++) {
            // eslint-disable-next-line eqeqeq
            if (
              j.properties.name ==
              this.projectCity[i].city[this.parentInfo.length - 1]
            ) {
              pointData[0].push({
                name: this.projectCity[i].name,
                children: this.projectCity[i].children,
                site: this.projectCity[i].site,
                value: [j.properties.center[0], j.properties.center[1], 1],
                city: j.properties.name,
                cityCode: j.properties.adcode,
              });
            }
          }*/
        }
      });
      this.pointData[this.parentInfo.length - 1] = pointData[0];
      console.log("pointData232", this.pointData);
      this.initEcharts(mapData, pointData);
      this.loading = false;
    },
    initEcharts(mapData, pointData) {
      console.log("pointData", pointData);
      console.log("mapData", mapData);
      // console.log(this.geoJson);
     /* for (var i = 0; i < this.geoJson.features.length; i++) {
        // 去除海南省
        // eslint-disable-next-line eqeqeq
        if (this.geoJson.features[i].properties.name == "海南省") {
          this.geoJson.features.splice(i, 1);
        }
      }*/
      this.myChart = echarts.init(this.$refs.allMap);
      if (this.parentInfo.length === 1) {
        echarts.registerMap("china", this.geoJson); // 注册
      } else {
        echarts.registerMap("map", this.geoJson); // 注册
      }
      var option = {
        timeline: {
          show: false,
        },
        baseOption: {
          geo: {
            map: this.parentInfo.length === 1 ? "china" : "map",
            zoom: 1.1,
            roam: true,
            // center: this.parentInfo.length === 1 ? ['118.83531246', '32.0267395887'] : false,
            // 地图上的名字
            label: {
              normal: {
                show: true,
                color: "rgb(249, 249, 249)", // 省份标签字体颜色
                formatter: (p) => {
                  console.log("[", p);
                  switch (p.name) {
                    case "内蒙古自治区":
                      p.name = "内蒙古";
                      break;
                    case "西藏自治区":
                      p.name = "西藏";
                      break;
                    case "新疆维吾尔自治区":
                      p.name = "新疆";
                      break;
                    case "宁夏回族自治区":
                      p.name = "宁夏";
                      break;
                    case "广西壮族自治区":
                      p.name = "广西";
                      break;
                    case "香港特别行政区":
                      p.name = "香港";
                      break;
                    case "澳门特别行政区":
                      p.name = "澳门";
                      break;
                    default:
                      break;
                  }
                  return p.name;
                },
              },
              emphasis: {
                show: true,
                color: "#f75a00",
              },
            },
            itemStyle: {
              normal: {
                areaColor: "rgba(0, 156, 249, 1)",
                borderColor: "#53D9FF",
                borderWidth: 1.3,
                shadowBlur: 15,
                shadowColor: "rgb(58,115,192)",
                shadowOffsetX: 7,
                shadowOffsetY: 6,
              },
              emphasis: {
                areaColor: "#8dd7fc",
                borderWidth: 1.6,
                shadowBlur: 25,
              },
            },
          },
        },
        options: [],
      };

      option.options.push({
        title: [
          {
            left: "center",
            top: 10,
            text: this.parentInfo[this.parentInfo.length - 1].cityName,
            textStyle: {
              color: "rgb(179, 239, 255)",
              fontSize: 16,
            },
          },
        ],
        series: [
          {
            name: "地图",
            type: "map",
            geoIndex: 0,
            map: this.parentInfo.length === 1 ? "china" : "map",
            roam: false,
            zoom: 1.3,
            tooltip: {
              trigger: "item",
              show: false,
              /*  formatter: function (params) {
                // console.log("params", params);
              },*/
            },
            label: {
              normal: {
                show: false,
              },

              emphasis: {
                show: false,
                textStyle: {
                  color: "#fff",
                },
              },
            },
            data: mapData[0],
          },
          {
            name: "散点",
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "fill",
            },
            label: {
              show: true,
              color: "#fff",
              formatter(params) {
                return params.data.site.length;
              },
            },
            tooltip: {
              trigger: "item",
              show: true,
              position: ["0%", "75%"],
              backgroundColor: "transparent",
              borderColor: "rgba(0, 156, 255, 0)",
              padding: 0,
              // formatter: '{b}'
              formatter(params) {
                console.log("effectScatter", params);

                let sites = params.data.site;
                let siteHtml = "";
                sites.map((item, index) => {
                  if (index < 5) {
                    siteHtml += ` <span><i class="dot" style="background:rgba(0, 156, 255, 1)"></i>${item}</span>`;
                  }
                });
                let html = `<div class='tooltip1 u-flex u-flex-col u-col-top'>
       <h3>${params.data.name}</h3>`;
                html = html + siteHtml + "</div>";

                return html;
              },
            },
            itemStyle: {
              normal: {
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(5,80,151,0.2)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(5,80,151,0.8)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,108,255,0.7)",
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            data: pointData[0],
            symbolSize: 20,
            showEffectOn: "render", // 加载完毕显示特效
          },
        ],
        tooltip: {
          trigger: "item",
          show: true,
          // formatter: '{b}'
        },
      });

      this.myChart.setOption(option, true);
      console.log("option", option.options);
      tools.loopShowTooltip(this.myChart, option.options[0], {
        loopSeries: false,
        seriesIndex: 1,
        interval: 5000,
      });
      this.myChart.off("click");
      this.myChart.on("click", this.echartsMapClick);
    },

    // 点击下钻
    echartsMapClick(params) {
      // console.log("wodianle");
      console.log("下钻", params);
      if (params.name === "建安区") {
        window.location.href =
          "http://cloud.xjpmf.cloud/#/power_monitor/power_monitor/1";
        return;
      }

      // eslint-disable-next-line eqeqeq
      if (params.seriesType == "map") {
        if (!params.data) {
          return;
        }
        if (
          // eslint-disable-next-line eqeqeq
          this.parentInfo[this.parentInfo.length - 1].code ==
          params.data.cityCode
        ) {
          return;
        }
        const data = params.data;
        this.parentInfo.push({
          cityName: data.name,
          code: data.cityCode,
        });
        this.getGeoJson(data.cityCode);
      } else {
        if (!params.data) {
          return;
        }
        if (
          // eslint-disable-next-line eqeqeq
          this.parentInfo[this.parentInfo.length - 1].code ==
          params.data.cityCode
        ) {
          return;
        }
        const data = params.data;
        this.parentInfo.push({
          cityName: data.city,
          code: data.cityCode,
        });
        this.getGeoJson(data.cityCode);
      }
    },
    chooseArea(val, index) {
      if (this.parentInfo.length === index + 1) {
        return;
      }
      this.parentInfo.splice(index + 1);
      this.getGeoJson(this.parentInfo[this.parentInfo.length - 1].code);
    },
  },
};
</script>
<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 100%;
  position: relative;
  // background: url('../assets/bg1.jpg') no-repeat;
  background-size: 100% 100%;
}

.mapChoose {
  position: absolute;
  left: 20px;
  top: 55px;
  color: #eee;

  .title {
    padding: 5px;
    border-top: 1px solid rgba(147, 235, 248, 0.8);
    border-bottom: 1px solid rgba(147, 235, 248, 0.8);
    cursor: pointer;
  }
  .icon {
    font-family: "simsun";
    font-size: 25px;
    margin: 0 11px;
  }
}
</style>
