<template>
  <el-dialog class="custom-dialog index-dialog" :visible.sync="newAlarmDialog">
    <template v-slot:title>
      <h3>报警信息</h3>
    </template>
    <div class="table-body">
      <table class="table1">
        <colgroup>
          <col width="20%" />
          <col width="20%" />
          <col width="20%" />
          <col width="20%" />
          <col width="20%" />
        </colgroup>
        <tr class="title">
          <td>报警时间</td>
          <td>报警站点</td>
          <td>报警位置</td>
          <td>报警内容</td>
          <td>报警等级</td>
        </tr>
        <tr v-for="(item, index) in newAlarmList" :key="index">
          <td>{{ item.alarmTime }}</td>
          <td>{{ item.serviceName }}</td>
          <td>{{ item.objectDesc }}</td>
          <td>{{ item.desc }}</td>
          <td :class="getName(item.alarmLevel)">{{ item.alarmLevel }}</td>
        </tr>
      </table>
      <el-pagination
        class="u-m-t-20 u-flex u-row-right u-p-r-60"
        layout="prev, next"
        :page-size="page_size"
      >
      </el-pagination>
    </div>
    <!-- 分页器 -->

    <div class="off" @click="newAlarmDialog = false"></div>
  </el-dialog>
</template>

<script>
import { getAlarms1 } from "@/api/screen";
export default {
  name: "AlarmDialog",
  data() {
    return {
      newAlarmDialog: false,
      newAlarmList: [],
      alarmNum: 0,
      newList: "",
      server_id: this.$store.state.screen.server_id,
      timer: "",
      name: "",
      page_size: 10,
    };
  },
  created() {},
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getAlarms() {
      getAlarms1({ name: this.name }).then((response) => {
        console.log("alarms", response);
        this.newAlarmList = [];
        for (let i = 0; i < response.res.length; i++) {
          this.newAlarmList.push({
            alarmTime: response.res[i].THETIME,
            serviceName: response.res[i].stationName,
            objectDesc: response.res[i].OBJDESC,
            desc: response.res[i].DESCRIPTION,
            alarmLevel: response.res[i].NAME,
          });
        }
      });
    },
    getName(name) {
      if (name == "可疑") {
        return "yellow1";
      } else if (name == "一般") {
        return "orange";
      } else if (name == "致命") {
        return "red";
      } else if (name == "操作") {
        return "violet";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.indexpage .table-body {
  height: 400px;
}
.publicpage .table1 {
  width: 100%;
  margin: 0 auto;
  table-layout: fixed;
}
.table1 {
  tr {
    height: 0.7rem;
    background: #011760;
    text-align: center;
    font-size: 0.22rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  tr:nth-of-type(odd) {
    background: rgba(0, 0, 0, 0.1);
  }
  .shou {
    cursor: pointer;
  }
  .title {
    height: 0.7rem;
    background: #0f2368 !important;
    font-size: 0.28rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #009cff;
  }
}
</style>
