<template>
  <div>
    <div class="public-panel panelh1">
      <h2>报警汇总</h2>
      <div class="chart" id="pie1"></div>
    </div>
    <AlarmDialog ref="dialog"></AlarmDialog>
  </div>
</template>

<script>
import { getHollowPie } from "@/assets/js/charts";
import { getBigDataAlarmnNum } from "@/api/screen";
import AlarmDialog from "@/views/screen/AlarmDialog";
export default {
  name: "Alarm",
  components: { AlarmDialog },
  data() {
    return {
      project_id: 1,
    };
  },
  created() {
    this.getBigDataAlarmnNum();
    setInterval(() => {
      this.getBigDataAlarmnNum();
    }, 300000);
  },
  methods: {
    getBigDataAlarmnNum() {
      getBigDataAlarmnNum({ project_id: this.project_id }).then((res) => {
        let dataValue = res.res;
        console.log("datavale", dataValue);
        this.$nextTick(() => {
          const mychart = getHollowPie("pie1", dataValue);
          mychart.on("click", (param) => {
            console.log("apss", param);
            this.$refs.dialog.newAlarmDialog = true;
            this.$refs.dialog.name = param.data.name;
            this.$refs.dialog.getAlarms();
          });
        });
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
